import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {InputGroup, Table} from "react-bootstrap";
import ContainerRowInput from "./UI/ContainerRowInput";
import $api from "../http";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {useIntl} from "react-intl";
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Loader from "./UI/Loader/Loader";
import Icon from "@reacticons/bootstrap-icons";

const ReleaseFormIn = (props) => {
    const {store} = useContext(Context);
    const [release, setRelease] = useState(props.release);
    const [show, setShow] = useState(false);
    const [containerList, setContainerList] =  useState('');
    const [errorcontainer, setErrorcontainer] = useState([]);

    const type = store.type;
    const [show2, setShow2] = useState(false);
    const modalClose2 = () => setShow2(false);

    const modalShow2 = (e) => {
        e.preventDefault();
        setShow2(true);
    }
    const modalClose = () => setShow(false);
    const modalShow = () => {
        setContainerList('')
        setErrorcontainer([])
        setShow(true);
    }
    let navigate = useNavigate();

    const intl = useIntl();
    const content = [{'value': 0, 'name':intl.messages.empty},{'value': 1, 'name':intl.messages.fullc}]
    const unload = [{'value': 0, 'name':"Не требуется"},{'value': 1, 'name':"Перегрузка"},{'value': 2, 'name':"Только площадка"}]
    const unload_type = [{'value': 1, 'name':"В контейнер"},{'value': 2, 'name':"В Автомобиль"}]
    console.log(release);
    const [save, setSave] = useState(false)
    let date = new Date();
    let dateString = date.getFullYear() +"-"+ String(date.getMonth() + 1).padStart(2, '0') +"-"+ String(date.getDate()).padStart(2, '0') ;
    const addNewPost = async (e)  => {
        e.preventDefault()
        setSave(true)
        const response = await $api.post('/release/save', release);
            if(response.status == '200'){
                navigate('/releases');
            } else {
                setSave(false)
            }
        }


/*  const checkEmptyRow = () => {
        let _release = {...release};
        let _containers = [..._release.containers];
        let _newContainers = [];
        console.log(_release)
        for(let i in _containers){

            if(_containers[i].number !== ''){
                _newContainers.push(_containers[i]);
            }
        }
        _release.containers = _newContainers;
        //setRelease(_release);

    }
    */
    const addContainerList = (e) => {
        e.preventDefault();
        let _release = {...release};
        let _containers = [..._release.containers];
        let newContainers = {};
        let str = containerList;
        let array = str.split(/\s*,\s*|\s*\r\s*|\s*\n\s*/)

        let error = [];


        if(typeof array == 'object') {
            for (let i in array) {
                if (array[i].length > 4) {
                    if (store.testContainer(array[i]) === false) {
                        if (array[i].length > 11) {
                            let test = array[i].split(' ');
                            if(typeof array == 'object') {
                                for (let a in test) {
                                    if (test[a].length > 4) {
                                        if (store.testContainer(test[a]) === false) {
                                            error.push(test[a])
                                        } else {
                                            if(findDoubleNumber(_containers, test[a])) {
                                                newContainers = {
                                                    type: '',
                                                    number: `${test[a]}`,
                                                    car_number: '',
                                                    fio: '',
                                                    count: 1,
                                                    connect: 0
                                                };
                                                _containers.push(newContainers);
                                            }
                                        }
                                    }
                                }
                            } else {
                                error.push(array[i])
                            }
                        } else {
                            error.push(array[i])
                        }
                    } else {
                        if(findDoubleNumber(_containers, array[i])) {
                            newContainers = {type: '', number: `${array[i]}`, car_number: '', fio: '', count: 1, connect: 0};
                            _containers.push(newContainers);
                        }

                    }
                }
            }
        }
        setContainerList('')
        let _newContainers = [];

        for(let i in _containers){

            if(_containers[i].number !== ''){
                _newContainers.push(_containers[i]);
            }
        }
        _release.containers = _newContainers;

        setRelease(_release);

        if(error.length > 0){
            setErrorcontainer(error)
        } else {
            modalClose()
        }

    }
    const findDoubleNumber = (array, value) => {
        for (let i in array){
            if(array[i].number === value){
                return false;
            }
        }
        return true;
    }




    const handleContainerList = (e) => {
        setContainerList(e.target.value)
    }

    const back = () => {
        navigate('/releases');
    }

    const addContainersRow = () => {
        let _release = {...release};
        let _containers = [..._release.containers];
        let newContainers = {type:'', number:'', car_number:'', fio:'', count:1, connect:0};
        _containers.push(newContainers);
        _release.containers = _containers;
        setRelease(_release);
    }

    const cancelStatus = async (e) => {
        e.preventDefault();
        let _release = {...release};
        _release.status = 5;
        const response = await $api.post('/release/save', _release);

        if(response.status == '200'){
            navigate('/releases');
        }



    }


    const rowDelete = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let _release = {...release};
        let _containers = [..._release.containers];
        _containers.splice(index, 1);
        _release.containers = _containers;
        setRelease(_release);

    }
    const handleChangeLoadedContainer = (e) => {
        let full = 0;
        if(release.full === 0){
            e.target.checked = true;
            full = 1;
        } else {
           e.target.checked = false;
        }
        let _release = {...release};
        _release.full = full;
        setRelease(_release);
    }

    const handleChange = (e,name) => {
        let _release = {...release};
        _release[name] = e.target.value;
        setRelease(_release);
    }

    const handleChangeCheckBox = (e,name) => {
        let value = 0;

        if(release[name] === 0){
            value = 1;
           e.target.checked = true;

        } else {
            value = 0

            e.target.checked = false;
        }
        let _release = {...release};
        _release[name] = value;
         setRelease(_release);
    }
    const handleChangeContainers = (value, name, index) => {
        let _release = {...release};
        let _containers = [..._release.containers];
        _containers[index][name] = value;
        _release.containers = _containers;
        setRelease(_release);

    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
        } else {

            addNewPost(e);
         }
    };
    if(save){
        return (
            <Loader />
        )
    }

    return (

        <Form noValidate onSubmit={handleSubmit} className="was-validated">
            <h3 className="my-5">{(release.unload == 1)? (release.unload_type == 1) ? intl.messages.order_title_reload_store :intl.messages.order_title_reload: intl.messages.order_title_in}:</h3>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label as={Col} sm="3">
                    {intl.messages.accept_terminal}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control plaintext readOnly value={intl.messages[store.user.terminal]} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.date_from}:
                </Form.Label>
                <Col sm="9">
                    {(release.status > 1) ? <Form.Control value={release.date_from} onChange={(e)=>handleChange(e,'date_from')} type="date" min={release.date_from?release.date_from:dateString} max={release.date_to} placeholder="" disabled  />:
                    <Form.Control value={release.date_from} onChange={(e)=>handleChange(e,'date_from')} type="date" min={release.date_from?release.date_from:dateString} max={release.date_to} placeholder="" required  />}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateTo">
                <Form.Label column sm="3">
                    {intl.messages.date_to}:
                </Form.Label>
                <Col sm="9">
                    {(release.status > 2) ? <Form.Control type="date" value={release.date_to} disabled  placeholder="" min={release.date_from??dateString} required/>:
                        <Form.Control type="date" value={release.date_to} onChange={(e)=>handleChange(e,'date_to')}  placeholder="" min={release.date_from??dateString} required/>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.release}:
                </Form.Label>
                <Col sm="9">
                    {(release.status > 2) ? <Form.Control type="text" placeholder="" value={release.release} disabled  /> :
                    <Form.Control type="text" placeholder="" value={release.release} onChange={(e)=>handleChange(e,'release')}  />
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_information}:
                </Form.Label>
                <Col sm="9">
                    {(release.status > 2) ? <Form.Control disabled as="textarea" value={release.description} onChange={(e)=>handleChange(e,'description')}  rows={3} />:
                        <Form.Control as="textarea" value={release.description} onChange={(e)=>handleChange(e,'description')}  rows={3} />
                    }

                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.number_out}:
                </Form.Label>
                <Col sm="9">
                    <InputGroup className="mb-3">

                        <span className="input-group-text">
                             {release.number_out ?
                                 (release.status > 1) ?
                                 <Form.Check type="checkbox" value="1" checked="true" disabled
                                              placeholder=""/> :
                                     <Form.Check type="checkbox" value="1" checked="true"
                                                 onChange={(e) => handleChangeCheckBox(e, 'number_out')} placeholder=""/> :
                                 (release.status > 1) ?
                                     <Form.Check disabled type="checkbox" value="1" onChange={(e) => handleChangeCheckBox(e, 'number_out')}
                                                 placeholder=""/>:
                                 <Form.Check type="checkbox" value="1" onChange={(e) => handleChangeCheckBox(e, 'number_out')}
                                             placeholder=""/>
                             }
                        </span>
                        <span className="input-group-text"> {intl.messages.number_out_text}</span>

                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.photo_report}:
                </Form.Label>
                <Col sm="9">
                    <InputGroup className="mb-3">

                        <span className="input-group-text">
                             {release.photo ?
                                 (release.status > 1) ?
                                     <Form.Check disabled type="checkbox" value="1" checked="true"

                                                 placeholder=""/> :
                                 <Form.Check type="checkbox" value="1" checked="true"
                                             onChange={(e) => handleChangeCheckBox(e, 'photo')}
                                             placeholder=""/>
                            :
                                 (release.status > 1) ?
                                     <Form.Check disabled type="checkbox" value="1" placeholder=""/>:
                                    <Form.Check type="checkbox" value="1"
                                             onChange={(e) => handleChangeCheckBox(e, 'photo')}
                                             placeholder=""/>
                             }
                        </span>
                        <span className="input-group-text"> {intl.messages.photo_report_text}</span>

                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.list_container_type}:
                </Form.Label>
                <Col sm="9">
                    <InputGroup className="mb-3">
                    <span className="input-group-text"> {(release.status > 1) ?
                        <Form.Select disabled value={release.type_container}
                        >
                            <option></option>
                            {type.map((s, i) =>
                                <option key={i} value={s.id}>{s.name}</option>
                            )}

                        </Form.Select>:
                    <Form.Select value={release.type_container} onChange={e => handleChange(e, 'type_container')}
                                 >
                        <option></option>
                        {type.map((s, i) =>
                            <option key={i} value={s.id}>{s.name}</option>
                        )}

                    </Form.Select>
                    }
                    </span>
                    <span className="input-group-text"> {intl.messages.list_containet_type_text}</span>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.contf}:
                </Form.Label>
                <Col sm="9">
                    {(release.status > 1) ? <Form.Select value={release.full} disabled >
                            <option></option>
                            {content.map((s, i) =>
                                <option key={i} value={s.value}>{s.name}</option>
                            )}

                        </Form.Select>:
                    <Form.Select value={release.full} onChange={e => handleChange(e, 'full')} required >
                                <option></option>
                                {content.map((s, i) =>
                                    <option key={i} value={s.value}>{s.name}</option>
                                )}

                     </Form.Select>
                    }
                </Col>
            </Form.Group>
            {release.full == 1 ?
                <Form.Group as={Row} className="mb-3" controlId="formReliase">
                    <Form.Label column sm="3">
                        Перегрузка:
                    </Form.Label>
                    <Col sm="9">
                        <InputGroup className="mb-3">
                            {(release.status > 1) ? <Form.Select value={release.unload} disabled >
                                    <option></option>
                                    {unload.map((s, i) =>
                                        <option key={i} value={s.value}>{s.name}</option>
                                    )}

                                </Form.Select>:
                                <Form.Select value={release.unload} onChange={e => handleChange(e, 'unload')} required >
                            <option></option>
                                {unload.map((s, i) =>
                                    <option key={i} value={s.value}>{s.name}</option>
                                )}

                        </Form.Select>
                            }


                             {release.unload > 0 ?
                                 <>
                                 <span className="input-group-text"> Куда перегружать? </span>
                                 {(release.status > 1) ?
                                     <Form.Select value={release.unload_type} disabled >
                                         <option></option>
                                         {unload_type.map((s, i) =>
                                             <option key={i} value={s.value}>{s.name}</option>
                                         )}

                                     </Form.Select>:
                                 <Form.Select value={release.unload_type} onChange={e => handleChange(e, 'unload_type')}
                                              required>
                                     <option></option>
                                     {unload_type.map((s, i) =>
                                         <option key={i} value={s.value}>{s.name}</option>
                                     )}

                                 </Form.Select>
                                 }
                                 </> :
                                 null}


                    </InputGroup>
                    </Col>
                </Form.Group>
            :null}

            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.applicant}:
                </Form.Label>
                <Col sm="9">
                    {(release.status > 1) ?
                        <Form.Control type="text" value={release.user} disabled />:
                    <Form.Control type="text" value={release.user} onChange={(e)=>handleChange(e,'user')}  placeholder="" required />
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.containers_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{intl.messages.type}</th>
                        <th><img src="/img/220.png" title="Подключение к электросети" /></th>
                        <th>{intl.messages.number_container}</th>
                        <th>{intl.messages.car_number}</th>
                        <th>{intl.messages.driver}</th>
                        <th></th>
                    </tr>
                    </thead>
                            <tbody>
                    {release.containers.map((row, index) =>
                        <ContainerRowInput key={index}
                                           index={index}
                                           row={row}
                                           type_container = {release.type_container}
                                           handleChange = {handleChangeContainers}
                                           delete={rowDelete}
                                           full = {release.full}
                                           unload = {release.unload}
                                           del={release.containers.length > 1 ? true: false}
                        />
                    )}

                    </tbody>
                </Table>
                        </Row>
                    <Row className="mb-3" >
                        {(release.status > 3) ? null:<>
                        <Col sm={{span: 3}}>
                            <Button variant="secondary" onClick={addContainersRow}>{intl.messages.add_container}</Button>
                        </Col>
                        <Col sm={{span: 3}}>
                            <Button variant="secondary" onClick={modalShow}>{intl.messages.list_containers}  </Button>
                        </Col>
                        </>}
                    </Row>
                </Col>


            </Form.Group>

            <Row className="mb-3 text-align-right" >
                <Col className="d-grid gap-2 d-md-block" >
                    <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                    {release.id ? (release.status > 2) ?
                        null :
                        <Button className="mx-md-1 btn-danger " type={"button"} onClick={(e)=> modalShow2(e)}>{intl.messages.cancel_order}</Button>:
                        null
                    }
                    {(release.status > 3)? null :
                    <Button className="mx-md-1" type={"submit"}>{release.id?intl.messages.save:intl.messages.create}</Button>
                    }
                </Col>

            </Row>
            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.messages.list_containers}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control id="addContainer" as="textarea" value={containerList} onChange={(e)=>handleContainerList(e)}  rows={6} />
                    <Row className="mt-3 text-align-right">
                        <Col className="mb-3 d-grid gap-2 d-md-block" >
                            <Alert variant="info">

                                {intl.messages.list_containers_message}</Alert>
                            {errorcontainer.length > 0 ?
                                <Alert  variant="danger"> <Alert.Heading>{intl.messages.incorrect_number}</Alert.Heading>
                                    {errorcontainer.join(', ')}</Alert>
                                : null
                            }
                        </Col>


                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e)=>addContainerList(e)}>
                        {intl.messages.add}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={modalClose2}>

                <Modal.Header closeButton>
                    <h4 className={'text-center'}>{intl.messages.cancel_order}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div><h5 className={'text-center'}>{intl.messages.cancel_order} <strong>{release.id}</strong></h5>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e) => cancelStatus(e)}>
                        {intl.messages.yes}
                    </Button>
                    <Button variant="secondary" onClick={(e) => modalClose2()}>
                        {intl.messages.no}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>

    );
};

export default ReleaseFormIn;
