import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ReleaseFormIn from "../components/ReleaseFormIn";
import ReleaseFormOut from "../components/ReleaseFormOut";
import {useFetching} from "../hooks/useFetching";
import $api from "../http";
import Loader from "../components/UI/Loader/Loader";
import ReleaseList from "../components/ReleaseList";
import ReleaseFormInEdit from "../components/ReleaseFormInEdit";
import ReleaseFormOutEdit from "../components/ReleaseFormOutEdit";
import {Context} from "../index";
import ReleaseFormOutF from "../components/ReleaseFormOutF";
import ReleaseFormOutFEdit from "../components/ReleaseFormOutFEdit";
const EditRelease = () => {
    const {store} = useContext(Context);
    const type = 'in';
    const {id} = useParams();
    console.log(id)
    const [release, setRelease] = useState([]);
    const [fetchRelease, isReleaseLoading] = useFetching(async () => {
        const response = await $api.get(`/release/getById/${id}`)

        setRelease(response.data.release);

    })
    useEffect(() => {
        fetchRelease()
    }, [])
    console.log(release);

    if(isReleaseLoading){
        return(
         <Loader/>
        )
    }
    else if(release.type_release === 'IN'){
        if(release.status == 1 ) {
            return (
                <ReleaseFormIn release={release}/>

            );
        } else if (release.status == 2) {
            return (
                <ReleaseFormIn release={release}/>

            );
        } else if (release.status == 3) {
        return (
            <ReleaseFormIn release={release}/>

        );
    }
    } else if(release.type_release === 'OUT') {
        if(release.status == 1) {
            if(release.cars.length == 0) {
                return (
                    <ReleaseFormOut release={release} currency={store.currency}/>
                );
            } else {
                return (
                    <ReleaseFormOutF release={release} currency={store.currency}/>
                );
            }
        } else if (release.status == 2) {
            if(release.cars.length == 0) {
                return (
                    <ReleaseFormOut release={release} currency={store.currency}/>

                );
            } else {
                return (
                    <ReleaseFormOutF release={release} currency={store.currency}/>
                );
            }
        }
    }
};

export default EditRelease;
