import React from 'react';
import {useNavigate} from 'react-router-dom';
import Datelocale from "./UI/Datelocale";
import {useIntl} from "react-intl";
import Badge from 'react-bootstrap/Badge';
import Icon from "@reacticons/bootstrap-icons";

const ReleaseItem = (props) => {
    const intl = useIntl();
    const status = { 1: intl.messages.new,2:intl.messages.agreed, 3:intl.messages.in_work,4:intl.messages.closed,5:intl.messages.denied,6:intl.messages.canceled,7:intl.messages.overdue}
    const type = {'IN':intl.messages.import, 'OUT':intl.messages.export, "RELOAD-S":intl.messages.reload_store,"RELOAD":intl.messages.reload}
    const navigate = useNavigate();
    const clickRow = (e) => {

        let link = "/release/" + e.target.dataset.link
        navigate(link)
    }
    const viewRow = (e) => {

        let link = "/release/view/" + e.target.dataset.link
        navigate(link)
    }
    if(props.post.status < 3) {
        return (
            <>
                <tr
                    className={(props.post.type_release == 'IN') ? 'table-success' : (props.post.type_release == 'OUT') ? 'table-danger' : 'table-info'}>
                    <td className="linkTd" onClick={clickRow} data-link={props.post.id}>{props.post.id}</td>
                    <td>{type[props.post.type_release]}</td>
                    <td><Datelocale date={props.post.date_from}/></td>
                    <td><Datelocale date={props.post.date_to}/></td>
                    <td>    {(props.post.status < 4)?
                            <Badge bg="success" className="pointer" data-id={props.post.id} data-to={props.post.date_to} onClick={props.extendRelease} >{intl.messages.extend}</Badge>: null}
                        {(props.post.status === 7)?
                            <Badge bg="success" className="pointer" data-id={props.post.id} data-to={props.post.date_to} onClick={props.extendRelease} >{intl.messages.extend}</Badge>: null}
                    </td>

                    <td className="containerCol">

                        {props.post.full ?
                            <span title={intl.messages.fullc} className="text-danger">{props.post.containers} <Icon name="boxes" className="Icon"></Icon></span>
                            : props.post.containers
                        }
                    </td>
                    <td>{props.post.containers_type}</td>
                    <td>{props.post.containers_count}</td>
                    <td>{props.post.ready}</td>
                    <td>{props.post.release}</td>
                    <td>{props.post.user}</td>
                    <td>{status[props.post.status]}</td>

                </tr>
            </>
        );
    } else {
        return (
            <>
                <tr  className={(props.post.type_release == 'IN') ? 'table-success' : (props.post.type_release == 'OUT') ? 'table-danger' : 'table-info'}>
                    <td className="linkTd" onClick={viewRow} data-link={props.post.id}>{props.post.id}</td>
                    <td>{type[props.post.type_release]}</td>
                    <td><Datelocale date={props.post.date_from}/></td>
                    <td><Datelocale date={props.post.date_to}/></td>
                    <td>    {(props.post.status < 4)?
                            <Badge bg="success" className="pointer" data-id={props.post.id} data-to={props.post.date_to} onClick={props.extendRelease} >{intl.messages.extend}</Badge>: null}
                        {(props.post.status === 7)?
                            <Badge bg="success" className="pointer" data-id={props.post.id} data-to={props.post.date_to} onClick={props.extendRelease} >{intl.messages.extend}</Badge>: null}</td>


                    <td>{props.post.full ?
                        <span title={intl.messages.fullc} className="text-danger">{props.post.containers} <Icon name="boxes" className="Icon"></Icon></span>
                        : props.post.containers
                    }
                    </td>
                    <td>{props.post.containers_type}</td>
                    <td>{props.post.containers_count}</td>
                    <td>{props.post.ready}</td>
                    <td>{props.post.release}</td>
                    <td>{props.post.user}</td>
                    <td>{status[props.post.status]}</td>
                    </tr>
            </>
        );
    }
};

export default ReleaseItem;
