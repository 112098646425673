import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Icon from "@reacticons/bootstrap-icons";
import {Context} from "../../index";
import {useIntl} from "react-intl";


const ContainerRowEdit = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const content = [{'value': 'E', 'name':intl.messages.empty},{'value': 'F', 'name':intl.messages.fullc}]

    const row = props.row;
    const handleChange = (e,name) => {
        props.handleChange(e.target.value,name, props.index);
    }
    const handleChangeContainer = (e) => {

        if(e.target.value.length > 0 && e.target.value < 11){
            e.target.setCustomValidity("incorect number");
        } else if(store.testContainer(e.target.value) === false){
            e.target.setCustomValidity("incorect number");
        } else {
            e.target.setCustomValidity("");
        }
        handleChange(e,'number')
    }



    const type = store.type;

    return (
        <tr>
            <td>

            </td>

            <td>
                <Form.Select disabled value={row.type} onChange={e => handleChange(e, 'type')}>
                    <option></option>
                    {type.map((s, i) =>
                        <option key={i} value={s.id}>{s.name}</option>
                    )}
                </Form.Select>
            </td>

            <td>
                <Form.Control readOnly className="container_test" value={row.number} type="text" placeholder=""/>
            </td>
            <td>
                <Form.Control value={row.count} type="number" readOnly/>
            </td>
            <td>
                <Form.Control readOnly type="text" value={row.car_number}/>


            </td>
            <td>
                <Form.Control readOnly value={row.fio} type="text" placeholder=""/>
            </td>
            <td>

            </td>
        </tr>
    );
}

export default ContainerRowEdit;