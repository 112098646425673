import React, {useContext, useState} from 'react';
import ContainerItem from "./ContainerItem";
import Row from "react-bootstrap/Row";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import Col from "react-bootstrap/Col";
import Icon from "@reacticons/bootstrap-icons";
import $api from "../http";
import {Context} from "../index";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Paginator from "./UI/Paginator";
import Loader from "./UI/Loader/Loader";



const ContainersList = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const [show, setShow] = useState(false);
    const [repair, setRepair] = useState(0);
    const [pfilter, setPfilter] = useState({number:'',type:'',tonnage:'',size:'',state:'',content:''});

    let type = store.type;
    let state = [{title:intl.messages.intact, value:"A"}, {title:intl.messages.damaged, value:"U"}, {title:intl.messages.refurbished, value:"UA"}, {title:intl.messages.refused_to_refurbish, value:"WR"},]
    let content = [{title:intl.messages.empty, value:"E"}, {title:intl.messages.fullc, value:"F"}]
    const [number, setNumber] = useState( '###');
    const handleChangeFilter = (e, name) => {
        let _filter = {...pfilter}
        _filter[name] = e.target.value
        setPfilter(_filter)
        props.handleFilter(_filter);

    }
    const modalClose = () => setShow(false);
    const modalShow = () => {
        setShow(true);
    }


    const sendRequestRepair = async () => {
         if(repair > 0) {
            const response = await $api.get(`/repair/create?id=${repair}`)
            if(response.status == 200) {
                setRepair(0)

            }
            modalClose();
        }
    }


    const requestRepair = (e) => {
        setRepair(e.target.closest('button').dataset.id)
        setNumber(e.target.closest('button').dataset.number)
        modalShow();

    }

    const downloadContainers = async (e) => {
        let locale = store.locale;
        await $api.get(`/containers/download?locale=${locale}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `containers.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })


    }

    if (!props.containersList.length) {
        return (
            <>
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        {intl.messages.containers_title}
                    </h3>
                    <Col style={{textAlign: 'left'}}>
                        <h6>{intl.messages.containers_count}: {store.containers.length} &nbsp;
                            <div className="iconSvg iconSvg-success">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                     width="32.000000pt" height="32.000000pt" viewBox="0 0 512.000000 512.000000"
                                     preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                       fill="#000000" stroke="none">
                                        <path d="M1040 3830 l-375 -117 -3 -1207 -2 -1206 385 0 385 0 0 1325 c0 729
-3 1325 -7 1324 -5 0 -177 -54 -383 -119z"/>
                                        <path d="M1630 2615 l0 -1325 1738 2 1737 3 3 1027 2 1028 -22 5 c-43 10
-3427 585 -3443 585 -13 0 -15 -143 -15 -1325z m498 1025 c18 -11 41 -34 52
-52 20 -32 20 -53 20 -1016 0 -833 -2 -988 -14 -1017 -19 -45 -69 -75 -125
-75 -37 0 -49 6 -82 39 l-39 39 0 1003 c0 989 0 1004 20 1037 36 59 110 77
168 42z m630 -114 l37 -34 3 -954 c2 -699 -1 -960 -9 -981 -39 -94 -171 -104
-223 -17 -14 26 -16 117 -16 980 0 669 3 958 11 973 22 43 61 67 112 67 40 0
53 -5 85 -34z m568 -90 l34 -34 0 -926 0 -926 -28 -31 c-39 -43 -113 -52 -156
-18 -58 46 -56 10 -56 974 0 730 2 891 14 919 20 49 60 76 113 76 38 0 50 -5
79 -34z m497 -57 c12 -6 30 -21 39 -33 16 -20 18 -83 18 -910 l0 -888 -34 -34
c-48 -48 -104 -48 -152 0 l-34 34 0 888 c0 827 2 890 18 910 34 43 98 57 145
33z m522 -65 c68 -32 65 8 65 -908 0 -889 1 -873 -51 -910 -44 -30 -115 -17
-151 28 -16 20 -18 81 -18 877 l0 856 21 27 c37 47 80 56 134 30z m501 -85
l29 -30 0 -830 0 -831 -33 -29 c-61 -55 -149 -30 -172 49 -8 25 -10 288 -8
840 l3 804 33 29 c46 42 107 41 148 -2z"/>
                                        <path d="M288 3596 l-258 -81 0 -1107 0 -1108 270 0 270 0 0 421 0 421 -72 -3
-73 -4 -3 253 -2 252 45 0 45 0 0 -205 0 -205 30 0 30 0 0 725 c0 574 -3 725
-12 724 -7 -1 -129 -38 -270 -83z"/>
                                    </g>
                                </svg>
                            </div>
                        </h6>
                    </Col>
                    <Col className="col-2" style={{textAlign: 'right'}}>
                        <button type="button" className="btn btn-success"  onClick={downloadContainers}><Icon className="dublFont" name="filetype-xlsx" title={intl.messages.download}  /> {intl.messages.download}</button>
                    </Col>
                </Row>

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{intl.messages.container}</th>
                        <th>{intl.messages.type}</th>
                        <th>{intl.messages.size}</th>
                        <th>{intl.messages.tonnage}</th>
                        <th>{intl.messages.state}</th>
                        <th>{intl.messages.content}</th>
                        <th>{intl.messages.date_from}</th>
                        <th>{intl.messages.days}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <td></td>
                        <td><input className="form-control" value={pfilter.number} type="text" onChange={(e) => handleChangeFilter(e, 'number')}/></td>
                        <td>
                            <Form.Select value={pfilter.type} onChange={(e) => handleChangeFilter(e, 'type')} >
                                <option value=""> - </option>
                                {type.map((s, i) =>
                                    <option key={i} value={s.id}>{s.name}</option>
                                )}
                            </Form.Select>
                        </td>
                        <td></td>
                        <td></td>
                        <td><Form.Select value={pfilter.state} onChange={(e) => handleChangeFilter(e, 'state')} >
                            <option value=""> - </option>
                            {state.map((s, i) =>
                                <option key={i} value={s.value}>{s.title}</option>
                            )}
                        </Form.Select></td>
                        <td><Form.Select value={pfilter.content} onChange={(e) => handleChangeFilter(e, 'content')} >
                            <option value=""> - </option>
                            {content.map((s, i) =>
                                <option key={i} value={s.value}>{s.title}</option>
                            )}
                        </Form.Select></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>

                    <tr><td style={{textAlign: 'center'}} colSpan={12}>{intl.messages.no_containers}</td></tr>

                    </tbody>
                </Table>


            </>
        )
    }

    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.containers_title}
                </h3>
                <Col style={{textAlign: 'left'}}>
                    <h6>{intl.messages.containers_count} : {props.isContainersLoading ? null: store.containers.length} &nbsp;
                        <div className="iconSvg iconSvg-success">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                             width="32.000000pt" height="32.000000pt" viewBox="0 0 512.000000 512.000000"
                             preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                               fill="#000000" stroke="none">
                                <path d="M1040 3830 l-375 -117 -3 -1207 -2 -1206 385 0 385 0 0 1325 c0 729
-3 1325 -7 1324 -5 0 -177 -54 -383 -119z"/>
                                <path d="M1630 2615 l0 -1325 1738 2 1737 3 3 1027 2 1028 -22 5 c-43 10
-3427 585 -3443 585 -13 0 -15 -143 -15 -1325z m498 1025 c18 -11 41 -34 52
-52 20 -32 20 -53 20 -1016 0 -833 -2 -988 -14 -1017 -19 -45 -69 -75 -125
-75 -37 0 -49 6 -82 39 l-39 39 0 1003 c0 989 0 1004 20 1037 36 59 110 77
168 42z m630 -114 l37 -34 3 -954 c2 -699 -1 -960 -9 -981 -39 -94 -171 -104
-223 -17 -14 26 -16 117 -16 980 0 669 3 958 11 973 22 43 61 67 112 67 40 0
53 -5 85 -34z m568 -90 l34 -34 0 -926 0 -926 -28 -31 c-39 -43 -113 -52 -156
-18 -58 46 -56 10 -56 974 0 730 2 891 14 919 20 49 60 76 113 76 38 0 50 -5
79 -34z m497 -57 c12 -6 30 -21 39 -33 16 -20 18 -83 18 -910 l0 -888 -34 -34
c-48 -48 -104 -48 -152 0 l-34 34 0 888 c0 827 2 890 18 910 34 43 98 57 145
33z m522 -65 c68 -32 65 8 65 -908 0 -889 1 -873 -51 -910 -44 -30 -115 -17
-151 28 -16 20 -18 81 -18 877 l0 856 21 27 c37 47 80 56 134 30z m501 -85
l29 -30 0 -830 0 -831 -33 -29 c-61 -55 -149 -30 -172 49 -8 25 -10 288 -8
840 l3 804 33 29 c46 42 107 41 148 -2z"/>
                                <path d="M288 3596 l-258 -81 0 -1107 0 -1108 270 0 270 0 0 421 0 421 -72 -3
-73 -4 -3 253 -2 252 45 0 45 0 0 -205 0 -205 30 0 30 0 0 725 c0 574 -3 725
-12 724 -7 -1 -129 -38 -270 -83z"/>
                            </g>
                        </svg>
                        </div>
                    </h6>
                </Col>
                <Col className="col-2" style={{textAlign: 'right'}}>
                    <button type="button" className="btn btn-success"  onClick={downloadContainers}><Icon className="dublFont" name="filetype-xlsx" title={intl.messages.download}  /> {intl.messages.download}</button>
                </Col>
            </Row>
            <Row>
                <Col className="col-12" >
                    {props.isContainersLoading && store.containers.length === 0 ?  <Loader /> :
                        <Paginator totalPages={props.totalPage} page={props.page} changePage={props.changePage} paginatorCount={props.handlePaginatorCount} limit={props.limit} />
                    }
                    </Col>
            </Row>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{intl.messages.container}</th>
                    <th>{intl.messages.type}</th>
                    <th>{intl.messages.size}</th>
                    <th>{intl.messages.tonnage}</th>
                    <th>{intl.messages.state}</th>
                    <th>{intl.messages.content}</th>
                    <th>{intl.messages.date_from}</th>
                    <th>{intl.messages.days}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <td></td>
                    <td><input className="form-control" value={pfilter.number} type="text" onChange={(e) => handleChangeFilter(e, 'number')}/></td>
                    <td>
                        <Form.Select value={pfilter.type} onChange={(e) => handleChangeFilter(e, 'type')} >
                            <option value=""> - </option>
                            {type.map((s, i) =>
                                <option key={i} value={s.id}>{s.name}</option>
                            )}
                        </Form.Select>
                    </td>
                    <td></td>
                    <td></td>
                    <td><Form.Select value={pfilter.state} onChange={(e) => handleChangeFilter(e, 'state')} >
                        <option value=""> - </option>
                        {state.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}
                    </Form.Select></td>
                    <td><Form.Select value={pfilter.content} onChange={(e) => handleChangeFilter(e, 'content')} >
                        <option value=""> - </option>
                        {content.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}
                    </Form.Select></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </thead>
                <tbody>


                {props.containersList.map((container, index) =>

                    <ContainerItem key={container.id} num={(props.page-1)*props.limit + index+1} container={container} requestRepair={requestRepair} blockedContainer={props.blockedContainer} unBlockedContainer={props.unBlockedContainer} downloadPhoto={props.downloadPhoto}/>
                )}
                </tbody>
            </Table>
            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {intl.messages.repair_estimate} {number} {intl.messages.repair_estimate2}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e)=>sendRequestRepair()}>
                        {intl.messages.yes}
                    </Button>
                    <Button variant="danger" onClick={(e)=>modalClose()}>
                        {intl.messages.canceled}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>


    );
};

export default ContainersList;
