import React, {useContext, useState} from 'react';
import ReleaseItem from "./ReleaseItem";
import MyButton from "./UI/button/MyButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {Context} from "../index";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import $api from "../http";
import Icon from "@reacticons/bootstrap-icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import Paginator from "./UI/Paginator";
import Loader from "./UI/Loader/Loader";


const ReleaseList = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const [pfilter, setPfilter] = useState({number:'',type:'',release:'',aplicant :'',status:''});

    let date = new Date();
    let dateMin = date.getFullYear() +"-"+ String(date.getMonth() + 1).padStart(2, '0') +"-"+ String(date.getDate()).padStart(2, '0') ;
    let dateMax = date.getFullYear() +"-"+ String(date.getMonth() + 2).padStart(2, '0') +"-"+ String(date.getDate()).padStart(2, '0') ;

    const [releaseExtend, setReleaseextend] = useState(0);
    const [_dateMin, setDatemin] = useState(dateMin)
    const [dateTo, setDateto] = useState(dateMin)
    const status = [{value:1,title:intl.messages.new},
        {value:2,title:intl.messages.agreed},
        {value:3,title:intl.messages.in_work},
        {value:4,title:intl.messages.closed},
        {value:5,title:intl.messages.denied},
        {value:6,title:intl.messages.canceled},
        {value:7,title:intl.messages.overdue}]
    const type = [{value:"IN", title:intl.messages.import},{value:"OUT", title:intl.messages.export},{value:"RELOAD-S", title:intl.messages.reload_store},{value:"RELOAD", title:intl.messages.reload}]

    const modalClose = () => setShow(false);
    const modalClose2 = () => setShow2(false);
    const modalShow = () => {
        setShow(true);
    }
    const modalShow2 = () => {
        setShow2(true);
    }
    const extendRelease = (e) => {
        setReleaseextend(e.target.dataset.id);
        let _dateto = new Date(e.target.dataset.to);
        let __dateto = _dateto.getFullYear() +"-"+ String(_dateto.getMonth() + 1).padStart(2, '0') +"-"+ String(_dateto.getDate()).padStart(2, '0') ;
       // if(__dateto > dateMin){
            setDatemin(__dateto)
            setDateto(__dateto)
       // } else {
       //     setDatemin(dateMin)
       //     setDateto(dateMin)
       // }
        modalShow();

    }
    const handleChangeFilter = (e, name) => {
        let _filter = {...pfilter}
        _filter[name] = e.target.value
        setPfilter(_filter)
        props.handleFilter(_filter);

    }





    const extendChange = (e) => {
        let dateValue =  e.target.value;
        if(dateValue > dateMax){
            dateValue = dateMax;
        }
        if(dateValue < _dateMin){
            dateValue = _dateMin;
        }
        setDateto(dateValue)
    }
    const sendExtendRelease = async () => {
        if (releaseExtend > 0) {
            let _post = {'id':releaseExtend, 'date_to':dateTo};
            const response = await $api.post(`/release/prolong`, _post)
            if (response.status == 200) {
                store.setOrders(response.data.release);
                modalClose();
            }

        } else {
            modalClose();
        }

    }


    const downloadRelease = async (e) => {
        let locale = store.locale;
        let daterange = document.querySelector('#daterange').value
        await $api.get(`/release/report?locale=${locale}&date=${daterange}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `report-release-${daterange}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                modalClose2();
            })


    }



    if (!props.orders.length) {
        return (
            <>
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        {intl.messages.orders}
                    </h3>
                </Row>
                <Row className="mb-3">
                    {props.isPostsLoading?<Loader /> : <>
                    <Col md={6}>
                        {props.conin ? <>
                                <h5>{intl.messages.waiting_import} <strong>{props.conin}</strong></h5>
                            </>
                            : null}
                    </Col>
                    <Col md={6}>
                        {props.conout ? <>
                                <h5 className="text-right_">{intl.messages.waiting_export} <strong>{props.conout}</strong></h5>
                            </>
                            : null}
                    </Col>
                    </>
                    }
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <MyButton variant="primary" to={'/release/new/in'}>{intl.messages.import_application}</MyButton>
                        <MyButton variant="info" to={'/release/new/reload'}>{intl.messages.reload_application}</MyButton>
                        <MyButton variant="success" to={'/release/new/cars'}>{intl.messages.export_application}</MyButton>
                        <MyButton variant="danger" to={'/release/new/out'}>{intl.messages.export_application2}</MyButton>
                    </Col>

                </Row>

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{intl.messages.type}</th>
                        <th>{intl.messages.date_from}</th>
                        <th>{intl.messages.date_to}</th>
                        <th></th>
                        <th className="containerCol">{intl.messages.container}</th>
                        <th className="typeCol">{intl.messages.type}</th>
                        <th>{intl.messages.total}</th>
                        <th>{intl.messages.ready}</th>
                        <th>{intl.messages.release}</th>
                        <th>{intl.messages.applicant}</th>
                        <th>{intl.messages.status}</th>

                    </tr>
                    <tr>
                        <td></td>
                        <td><Form.Select value={pfilter.type} onChange={(e) => handleChangeFilter(e, 'type')} >
                            <option value=""> - </option>
                            {type.map((s, i) =>
                                <option key={i} value={s.value}>{s.title}</option>
                            )}
                        </Form.Select></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="containerCol"><input className="form-control" value={pfilter.number} type="text" onChange={(e) => handleChangeFilter(e, 'number')}/></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><input className="form-control" value={pfilter.release} type="text" onChange={(e) => handleChangeFilter(e, 'release')}/></td>
                        <td><input className="form-control" value={pfilter.aplicant} type="text" onChange={(e) => handleChangeFilter(e, 'aplicant')}/></td>
                        <td><Form.Select value={pfilter.status} onChange={(e) => handleChangeFilter(e, 'status')} >
                            <option value=""> - </option>
                            {status.map((s, i) =>
                                <option key={i} value={s.value}>{s.title}</option>
                            )}
                        </Form.Select></td>

                    </tr>
                    </thead>
                    <tbody>

                    <tr><td style={{textAlign: 'center'}}>
                        {intl.messages.no_orders}
                    </td></tr>

                    </tbody>
                </Table>

            </>
        )
    }

    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.orders}
                </h3>
            </Row>
            <Row className="mb-3">
                {props.isPostsLoading?<Loader /> : <>
                <Col md={6}>
                    {props.conin ? <>
                        <h5>{intl.messages.waiting_import} <strong>{props.conin}</strong></h5>
                    </>
                        : null}
                </Col>
                <Col md={6}>
                    {props.conout ? <>
                            <h5 className="text-right_">{intl.messages.waiting_export} <strong>{props.conout}</strong></h5>
                        </>
                        : null}
                </Col>
                </>
            }
            </Row>
            <Row className="mb-3">
                <Col md={9}>
                    <MyButton variant="primary" to={'/release/new/in'}>{intl.messages.import_application}</MyButton>
                    <MyButton variant="info" to={'/release/new/reload'}>{intl.messages.reload_application}</MyButton>
                    <MyButton variant="success" to={'/release/new/cars'}>{intl.messages.export_application}</MyButton>
                    <MyButton variant="danger" to={'/release/new/out'}>{intl.messages.export_application2}</MyButton>
                </Col>
                <Col md={3} style={{textAlign: 'right'}}>
                    <button type="button" className="btn btn-success" onClick={modalShow2} ><Icon className="dublFont" name="filetype-xlsx" title={intl.messages.download}  /> {intl.messages.download}</button>
                </Col>
            </Row>
            <Row>
                <Col className="col-12" >
                    {props.isPostsLoading? <Loader /> :
                        <Paginator totalPages={props.totalPage} page={props.page} changePage={props.changePage} paginatorCount={props.handlePaginatorCount} limit={props.limit} />
                    }
                </Col>

            </Row>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{intl.messages.type}</th>
                    <th>{intl.messages.date_from}</th>
                    <th>{intl.messages.date_to}</th>
                    <th></th>
                    <th className="containerCol">{intl.messages.container}</th>
                    <th className="typeCol">{intl.messages.type}</th>
                    <th>{intl.messages.total}</th>
                    <th>{intl.messages.ready}</th>
                    <th>{intl.messages.release}</th>
                    <th>{intl.messages.applicant}</th>
                    <th>{intl.messages.status}</th>

                </tr>
                <tr>
                    <td></td>
                    <td><Form.Select value={pfilter.type} onChange={(e) => handleChangeFilter(e, 'type')} >
                        <option value=""> - </option>
                        {type.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}
                    </Form.Select></td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="containerCol"><input className="form-control" value={pfilter.number} type="text" onChange={(e) => handleChangeFilter(e, 'number')}/></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><input className="form-control" value={pfilter.release} type="text" onChange={(e) => handleChangeFilter(e, 'release')}/></td>
                    <td><input className="form-control" value={pfilter.aplicant} type="text" onChange={(e) => handleChangeFilter(e, 'aplicant')}/></td>
                    <td><Form.Select value={pfilter.status} onChange={(e) => handleChangeFilter(e, 'status')} >
                        <option value=""> - </option>
                        {status.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}
                    </Form.Select></td>

                </tr>
                </thead>
                <tbody>


                {props.orders.map((post, index) =>

                    <ReleaseItem key={post.id} post={post} extendRelease={extendRelease} />
                )}
                </tbody>
            </Table>
            <Row>
                <Col className="col-12" >
                    {props.isPostsLoading && store.orders.length === 0 ? null :
                        <Paginator totalPages={props.totalPage} page={props.page} changePage={props.changePage} paginatorCount={props.handlePaginatorCount} limit={props.limit} />
                    }

                </Col>

            </Row>
            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                    <h4>
                        {intl.messages.extend_application} {intl.messages._no}{releaseExtend} {intl.messages.till}:
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="date" value={dateTo} onChange={(e)=>extendChange(e)}  placeholder="" min={_dateMin} max={dateMax} required/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e)=>sendExtendRelease()}>
                        {intl.messages.extend}
                    </Button>
                    <Button variant="danger" onClick={(e)=>modalClose()}>
                        {intl.messages.canceled}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={modalClose2}>
                <Modal.Header closeButton>
                    <h4>
                        {intl.messages.generate_report} :
                    </h4>
                </Modal.Header>
                <Modal.Body> <label>{intl.messages.range}:</label>
                    <DateRangePicker initialSettings={{ startDate: '05/01/2023' }}>

                        <input type="text" id='daterange' className="form-control" />
                    </DateRangePicker>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e)=>downloadRelease()}>
                        {intl.messages.download}
                    </Button>
                    <Button variant="danger" onClick={(e)=>modalClose2()}>
                        {intl.messages.canceled}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>


    );
};

export default ReleaseList;
