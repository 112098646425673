import React, {useContext, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Table} from "react-bootstrap";
import $api from "../http";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {useIntl} from "react-intl";
import {useFetching} from "../hooks/useFetching";
import ContainerRowOutputEdit from "./UI/ContainerRowOutputEdit";
import CarRowOutputEdit from "./UI/CarRowOutputEdit";


const ReleaseFormOutContainer = (props) => {
    const {store} = useContext(Context);
    let navigate = useNavigate();
    const [release, setRelease] = useState(props.release);
    const intl = useIntl();
    const [fetchType] = useFetching(async (limit, page) => {
        const response = await $api.get('/containers/type')

        store.setTypeOut(response.data.type);
    })
    useEffect(() => {

        fetchType();
        const interval = setInterval(fetchType, 60000);
        return () => clearInterval(interval);
     },[])
    const addNewPost = async (e)  => {
        e.preventDefault()
        const response = await $api.post('/release/save', release);
        if(response.status == '200'){

            navigate('/releases');
        }
    }
    const back = () => {
        navigate('/releases');
    }



    const rowDelete = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let _release = {...release};
        let _containers = [..._release.containers];
        _containers.splice(index, 1);
        _release.containers = _containers;
        setRelease(_release);


    }
    const carsDelete = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let _release = {...release};
        let _cars = [..._release.cars];
        _cars.splice(index, 1);
        _release.cars = _cars;
        setRelease(_release);


    }
    const handleChange = (e,name) => {

        let _release = {...release};
        _release[name] = e.target.value;
        setRelease(_release);

    }
    const handleChangeContainers = (value, name, index) => {

        let _release = {...release};
        let _containers = [..._release.containers];
        _containers[index][name] = value;
        _release.containers = _containers;
        setRelease(_release);


    }

    const calculateCount = () => {
        let _release = {...release};
        let _containers = [..._release.containers];
        let countContainer = 0;
        let countCar = 0;
        for(let i in _containers){
            countContainer += _containers[i].count * 1
        }
        let _cars = [..._release.cars];
        for(let c in _cars){
            countCar += _cars[c].count * 1
        }

        let _count =  document.querySelectorAll(".countSum");
        if(countCar !== countContainer){

           _count.forEach(el => el.setCustomValidity("incorect count"))
        } else {
            _count.forEach(el => el.setCustomValidity(""))
        }

    }
    const cancelStatus = async (e) => {
        e.preventDefault();
        let _release = {...release};
        _release.status = 5;
        const response = await $api.post('/release/save', _release);

        if(response.status == '200'){
            navigate('/releases');
        }



    }
    const handleChangeCars = (e, name, index) => {
        let _release = {...release};
        let _cars = [..._release.cars];
        _cars[index][name] = e.target.value;
        _release.cars = _cars;
        setRelease(_release);


    }
    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
        } else {
             addNewPost(e);
        }
    };

    useEffect(() => {
        calculateCount()
    },[])

    return (

        <Form noValidate onSubmit={handleSubmit} className="was-validated">
            <h3 className="my-5">{intl.messages.order_title_out}:</h3>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label as={Col} sm="3">
                    {intl.messages.issue_terminal}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control plaintext readOnly value={intl.messages[store.user.terminal]} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.date_from}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control value={release.date_from} onChange={(e)=>handleChange(e,'date_from')} type="date" placeholder="" required />

                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateTo">
                <Form.Label column sm="3">
                    {intl.messages.date_to}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="date" value={release.date_to} onChange={(e)=>handleChange(e,'date_to')}  placeholder=""  required/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.release}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" placeholder="" value={release.release} onChange={(e)=>handleChange(e,'release')}  required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.carrier}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={release.car_firm} onChange={(e)=>handleChange(e,'car_firm')}  placeholder="" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.applicant}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={release.user} onChange={(e)=>handleChange(e,'user')}  placeholder="" required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_email}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="email" value={release.add_email} onChange={(e)=>handleChange(e,'add_email')}  placeholder="" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_information}
                </Form.Label>
                <Col sm="9">
                    <Form.Control as="textarea" value={release.description} onChange={(e)=>handleChange(e,'description')}  rows={3} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.containers_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{intl.messages.size}</th>
                                <th>{intl.messages.type}</th>
                                <th>{intl.messages.tonnage}</th>
                                <th>{intl.messages.number_container}</th>
                                <th>{intl.messages.quantity}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {release.containers.map((row, index) =>
                                <ContainerRowOutputEdit key={index}
                                                   index={index}
                                                   row={row}
                                                   car_firm={release.car_firm}
                                                   handleChange = {handleChangeContainers}
                                                   delete={rowDelete}
                                                   del={release.containers.length > 1 ? true: false}
                                />
                            )}

                            </tbody>
                        </Table>
                    </Row>

                </Col>
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.car_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{intl.messages.number}</th>
                                <th>{intl.messages.driver}</th>
                                <th>{intl.messages.phone}</th>
                                <th>{intl.messages.quantity}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {release.cars.map((row, index) =>
                                <CarRowOutputEdit key={index}
                                             index={index}
                                             row={row}
                                             handleChange = {handleChangeCars}
                                             delete={carsDelete}
                                             del={release.cars.length > 1 ? true: false}
                                />
                            )}

                            </tbody>
                        </Table>
                    </Row>

                </Col>

            </Form.Group>

            <Row className="mb-3 text-align-right" >
                <Col className="d-grid gap-2 d-md-block" >
                    <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                    {release.id ? <Button className="mx-md-1 btn-danger " type={"button"} onClick={(e)=>cancelStatus(e)}>{intl.messages.cancel}</Button>: ''}
                    <Button className="mx-md-1" type={"submit"}>{release.id?intl.messages.save:intl.messages.create}</Button>
                </Col>

            </Row>
        </Form>

    );
};

export default ReleaseFormOutContainer;
