import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {Context} from "../index";
import {Link} from "react-router-dom";
import {Nav} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useFetching} from "../hooks/useFetching";
import $api from "../http";
import Badge from "react-bootstrap/Badge";
const Navigation = () => {
    const {store} = useContext(Context)
    const intl = useIntl();
    const [count_alert, setCount_allert] = useState({repair:0,bills:0});
    const [fetchAlert] = useFetching(async (limit, page) => {
        const response = await $api.get('/alert')
        if(response.status == 200) {
            if(response.data.alert.repair !== count_alert.repair ||
            response.data.alert.bills !== count_alert.bills){
                setCount_allert(response.data.alert);
            }

        } else {
            store.logout();
        }

    })
    useEffect(() => {
        fetchAlert();
        const interval = setInterval(fetchAlert, 30000);
        return () => clearInterval(interval);
    }, [])
    const languageRu = () => {
        store.setLocale('ru-RU')
        localStorage.setItem('locale','ru-RU')
    }
    const languageEn = () => {
        store.setLocale('en-US')
        localStorage.setItem('locale','en-US')
    }
    const languageZh = () => {
        store.setLocale('zh-CN')
        localStorage.setItem('locale','zh-CN')
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="success" sticky="top" variant="dark">
            <Container>
                <Navbar.Brand >
                    <Link className="navbar-brand" to="/">{intl.messages[store.user.terminal]}{store.user?<> - {store.user.stock}</>:null}   </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Link className="nav-link" to="/containers" >{intl.messages.containers}</Link>
                        <Link className="nav-link" to="/releases" >{intl.messages.orders}</Link>
                        <Link className="nav-link" to="/movings" >{intl.messages.movement}</Link>
                        <Link className="nav-link" to="/bills" >{intl.messages.bills}
                            {count_alert.bills > 0 ?
                                <sup><Badge bg="danger">{count_alert.bills}</Badge></sup>
                                :null
                            }
                        </Link>
                        <Link className="nav-link" to="/repairs" >{intl.messages.repairs}
                            {count_alert.repair > 0 ?
                                <sup><Badge bg="danger">{count_alert.repair}</Badge></sup>
                                :null
                            }

                        </Link>
                        <Link className="nav-link" to="/services" >{intl.messages.services}
                            {count_alert.services > 0 ?
                                <sup><Badge bg="danger">{count_alert.repair}</Badge></sup>
                                :null
                            }

                        </Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end navbar-nav lang-mob">
                    <span onClick={languageRu} className={(store.locale === 'ru-RU')?'lang active nav-link': 'nav-link lang'}>Рус</span><span> / </span>
                    <span onClick={languageEn} className={(store.locale === 'en-US')?'lang active nav-link': 'nav-link lang'}>Eng</span><span> / </span>
                    <span onClick={languageZh} className={(store.locale === 'zh-CN')?'lang active nav-link': 'nav-link lang'}>中文</span>
                    <a href="#" className="nav-link" onClick={() => store.logout()}>
                        {intl.messages.exit}
                    </a>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;