import React, {useContext, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import Icon from "@reacticons/bootstrap-icons";
import {Context} from "../../index";
import classes from "./Autocomplet.module.css";
import {useFetching} from "../../hooks/useFetching";
import $api from "../../http";
import {useIntl} from "react-intl";

const ContainerRowOutputOutCar = (props) => {
    const {store} = useContext(Context);
    const [containers, setContainers] = useState(store.getContainers());
    const row = props.row;
    const intl = useIntl();
    const [show, setShow] = useState(true);
    const [gvalid, setGvalid] = useState(true);
    let type = store.typeOut;

    let groupValid = true;
    useEffect(() => {

       if (props.row.type !== '') {
            groupValid = false;
        }

        setGvalid(groupValid);
       if(props.addlist === true){
           setGvalid(false);
           setShow(false);
       }

    }, [])

    useEffect(() => {
        type = store.typeOut;
    },[store])
    const handleChange = (e, name) => {
        if(name == 'car_number'){
            if(e.target.value != "") {

                    e.target.setCustomValidity("");

            } else {
                e.target.setCustomValidity("");
                }
        }
        if(name == 'fio'){
            if(e.target.value != "") {
                let regexp = /^[a-zA-Zа-яА-Я]{2,20} [a-zA-Zа-яА-Я]{2,20} [a-zA-Zа-яА-Я]{2,20}$/
                if (regexp.test(e.target.value)) {
                    e.target.setCustomValidity("");
                } else {
                    e.target.setCustomValidity("incorect number");
                }
            } else {
                e.target.setCustomValidity("");
            }
        }
        props.handleChange(e.target.value,name, props.index);



    }
    const handleChangeContainer = (e) => {

        if(e.target.value.length < 11){
            e.target.setCustomValidity("incorect number");
            setGvalid(true);
        } else if(store.testContainer(e.target.value) === false){
            e.target.setCustomValidity("incorect number");
            setGvalid(true);
        } else if(store.testContainer(e.target.value) === true){
            if(store.testContainerNumberToStockUnlock(e.target.value) === false){
                e.target.setCustomValidity("Container not in stock");
                setGvalid(true);
            }
            else if(store.testContainerNumberToStock(e.target.value) === false){
                e.target.setCustomValidity("Container not in stock");
                setGvalid(true);
            } else {

                e.target.setCustomValidity("");
                setGvalid(false);
            }

        }
        handleChange(e,'number')
    }
    const handleType = (e) => {
        props.handleChange(e.target.value,'type', props.index);
        if(e.target.value == ''){

            setGvalid(true);


        } else {

            setGvalid(false);
        }
    }
    const filterContainer = store.containers.filter(container => {
        return container.is_blocked == 1 ? null : container.number.toLowerCase().includes(row.number.toLowerCase())
    })
    const selectContainer = (e) => {

        props.handleChange(e.target.dataset.number,'number', props.index);
        e.target.parentNode.previousElementSibling.setCustomValidity("");
        setShow(false)
        setGvalid(false);

    }



    return (
        <tr>
            <td class={props.index}>

            </td>

            <td>
                {!gvalid ?
                    <Form.Select value={row.type} onChange={e => handleChange(e, 'type')}>
                        <option></option>
                        {type.map((s, i) =>
                            <option key={i} value={s.id}>{s.name}</option>
                        )}
                    </Form.Select>
                    :
                    <Form.Select value={row.type} onChange={e => handleType(e)} required >
                        <option></option>
                        {type.map((s, i) =>
                            <option key={i} value={s.id}>{s.name}</option>
                        )}
                    </Form.Select>
                }
            </td>

            <td className={classes.position}>
                {!gvalid ?
                    <Form.Control autoComplete="off"  value={row.number} onChange={e => handleChangeContainer(e)} type="text"
                                  placeholder=""/>
                    :
                    <Form.Control autoComplete="off" value={row.number} onChange={e => handleChangeContainer(e)} type="text"
                                  placeholder="" required/>
                }
                <ul className={classes.autocomplet} >
                    { row.number ? show ?
                        filterContainer.map((el, i) =>
                            <li key={el.id} data-number={el.number}
                                className={el.content == 'F' ? 'text-danger ' + classes.autocomplet__item : classes.autocomplet__item}
                                onClick={e => selectContainer(e)}>{el.number}{el.content == 'F' ? intl.messages.full_container : null}</li>
                        )
                            : null
                        : null
                    }
                </ul>
            </td>
            <td>{row.number ?
                <Form.Control value="1" onChange={e => handleChange(e, 'count')} type="number" placeholder=""  readOnly  /> :
                <Form.Control  value={row.count} onChange={e => handleChange(e, 'count')} type="number" placeholder=""  />
            }

            </td>


            <td>
                {!props.del ? '' :
                    <a href="#" className="text-warning" onClick={(e) => props.delete(e, props.index)}>
                        <Icon name="trash"/>
                    </a>
                }
            </td>
        </tr>
    );
}

export default ContainerRowOutputOutCar;