import axios from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";
import {Context} from "../index";
import {useContext} from "react";

export const API_URL = 'https://containera.online/api/v1';

const $api = axios.create({
    withCredentials: false,
    baseURL: API_URL,

})



$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
});

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const {store} = useContext(Context);
    const originalRequest = error.config;
    console.log(error);
    if(error.response.status == 401 && error.config && !error.config._isRetry){
        originalRequest._isRetry = true;
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials:true})
            localStorage.setItem('token', response.data.accessToken);
            return $api.request(originalRequest);
        } catch (e){
            console.log('not authorized');
            await store.logout()

        }
    }
    throw error.response;
});

export default $api;