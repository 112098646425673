import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Icon from "@reacticons/bootstrap-icons";
import {Context} from "../../index";
import {useIntl} from "react-intl";
import {InputGroup} from "react-bootstrap";


const ContainerRowInput = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const content = [{'value': 'E', 'name':intl.messages.empty},{'value': 'F', 'name':intl.messages.fullc}]
    const row = props.row;
    const type_container = props.type_container;
    const type = store.type;
    const getReload = (element, id) => {
        if(element.id == id){
            return element.reload;
        }
    }



    const handleChange = (e,name) => {
        let reload;
        console.log(props.unload)
        if(name == 'type' && props.unload == 1){
            let type_value = e.target.value;
            type.filter((_type) => {
                if(_type.id == type_value ){
                    reload = _type.reload
                }
            })
            props.handleChange(reload,'reload', props.index);
        } else if(props.unload == 1){
            let type_value = row.type;
            type.filter((_type) => {
                if(_type.id == type_value ){
                    reload = _type.reload
                }
            })
            props.handleChange(reload,'reload', props.index);
        }
        props.handleChange(e.target.value,name, props.index);
    }

    const handleChangeContainer = (e) => {

        if(e.target.value.length > 0 && e.target.value < 11){
            e.target.setCustomValidity("incorect number");
        } else if(store.testContainer(e.target.value) === false){
            e.target.setCustomValidity("incorect number");
        } else {
            e.target.setCustomValidity("");
        }
        handleChange(e,'number')
    }

    const handleChangeChekbox = (e,name) => {
        let value = 0;
        if(row[name] === 0){
            value = 1;
            e.target.checked = true;
        } else {
            value = 0

            e.target.checked = false;
        }
        props.handleChange(value,name, props.index);
    }

    if(type_container > 0){
        row.type = type_container;
    }



    if(props.full == 1){

       return (<>
           <tr>
               <td rowSpan="4">
                   {props.index + 1}
               </td>

               <td>

                   {type_container > 0 ?

                       <Form.Select value={row.type} onChange={e => handleChange(e, 'type')} required>
                           <option></option>
                           {type.map((s, i) =>
                               <option key={i} value={s.id}>{s.name}</option>
                           )}
                       </Form.Select>
                       :
                       <Form.Select value={row.type} onChange={e => handleChange(e, 'type')} required>
                           <option></option>
                           {type.map((s, i) =>
                               <option key={i} value={s.id}>{s.name}</option>
                           )}
                       </Form.Select>

                   }
               </td>
               <td>
                   {row.type == 33 || row.type == 34 || type_container == 33 || type_container == 34 ?
                       <InputGroup className="mb-3">
                    <span className="input-group-text">
                    <Form.Check type="checkbox" value={row.connect}
                                onChange={(e) => handleChangeChekbox(e, 'connect')} placeholder=""/></span>

                       </InputGroup>
                       : null}
               </td>


               <td>
                   <Form.Control className="container_test" value={row.number} onChange={e => handleChangeContainer(e)}
                                 type="text" placeholder="" required/>
               </td>
               <td>
                   <Form.Control type="text" value={row.car_number} onChange={e => handleChange(e, 'car_number')}/>


               </td>
               <td>
                   <Form.Control value={row.fio} onChange={e => handleChange(e, 'fio')} type="text" placeholder=""/>
               </td>
               <td rowSpan="4">
                   {!props.del ? '' :
                       <a href="#" className="text-warning" onClick={(e) => props.delete(e, props.index)}>
                           <Icon name="trash"/>
                       </a>
                   }
               </td>
           </tr>
           <tr>


               <td colSpan="5">
                   <InputGroup>
                       <span className="input-group-text"> Наименование груза </span>
                       <Form.Control value={row.content_name}
                                     onChange={e => handleChange(e, 'content_name')}
                                     type="text" placeholder="" />
                       <span className="input-group-text">Инвойс</span>
                       <Form.Control value={row.invoice}
                                     onChange={e => handleChange(e, 'invoice')}
                                     type="text" placeholder=""/>
                   </InputGroup>
               </td>
           </tr><tr><td colSpan="5">
           <InputGroup>
                       <span className="input-group-text"> Количество мест </span>
                       {(props.unload == 1) ?
                           <Form.Control value={row.content_count}
                                         onChange={e => handleChange(e, 'content_count')}
                                         type="text" placeholder="" /> :
                           <Form.Control value={row.content_count}
                                         onChange={e => handleChange(e, 'content_count')}
                                         type="text" placeholder=""/>
                       }
                       <span className="input-group-text"> Масса груза </span>
                       <Form.Control value={row.content_weight}
                                     onChange={e => handleChange(e, 'content_weight')}
                                     type="text" placeholder=""/>

                       <span className="input-group-text">Ставка</span>
                       <Form.Control value={row.reload}

                                     type="text" placeholder=""/>
                   </InputGroup>
               </td>

           </tr>
           <tr>


               <td colSpan="5">

                   <Form.Control as="textarea" placeholder="Комментарий к грузу и перегрузке"
                                 value={row.content_comment} onChange={(e) => handleChange(e, 'content_comment')}
                                 rows={2}/>

               </td>

           </tr>
       </>)
    } else {
        return (
            <tr>
                <td>
                    {props.index + 1}
                </td>

                <td>
                    {type_container > 0 ?
                        <Form.Select value={type_container} onChange={e => handleChange(e, 'type')} required>
                            <option></option>
                            {type.map((s, i) =>
                                <option key={i} value={s.id}>{s.name}</option>
                            )}
                        </Form.Select> :
                        <Form.Select value={row.type} onChange={e => handleChange(e, 'type')} required>
                            <option></option>
                            {type.map((s, i) =>
                                <option key={i} value={s.id}>{s.name}</option>
                            )}
                        </Form.Select>

                    }
                </td>
                <td>
                    {row.type == 33 || row.type == 34 || type_container == 33 || type_container == 34 ?
                        <InputGroup className="mb-3">
                    <span className="input-group-text">
                    <Form.Check type="checkbox" value={row.connect}
                                onChange={(e) => handleChangeChekbox(e, 'connect')} placeholder=""/></span>

                        </InputGroup>
                        : null}
                </td>


                <td>
                    <Form.Control className="container_test" value={row.number} onChange={e => handleChangeContainer(e)}
                                  type="text" placeholder="" required/>
                </td>
                <td>
                    <Form.Control type="text" value={row.car_number} onChange={e => handleChange(e, 'car_number')}/>


                </td>
                <td>
                    <Form.Control value={row.fio} onChange={e => handleChange(e, 'fio')} type="text" placeholder=""/>
                </td>
                <td>
                    {!props.del ? '' :
                        <a href="#" className="text-warning" onClick={(e) => props.delete(e, props.index)}>
                            <Icon name="trash"/>
                        </a>
                    }
                </td>
            </tr>
        );
    }
}

export default ContainerRowInput;