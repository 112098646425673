import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import ReleaseFormIn from "../components/ReleaseFormIn";
import ReleaseFormOut from "../components/ReleaseFormOut";
import {Context} from "../index";
import ReleaseFormOutF from "../components/ReleaseFormOutF";
const CreateRelease = () => {
    const {type} = useParams();
    const {store} = useContext(Context);
    console.log(type);
    if(type === 'in'){
        const release = {id:0, date_from:'', date_to:'', release:'',type_release:'IN',description:'',user:store.user.name, status:1, full:'',unload:0,unload_type:0,photo:1,type_container:'',number_out:0 , delivery_address:"", agreed_price:'', containers:[{number:'', type:'',car_number:'', content:'', fio:'',count:1,connect:0,content_count:'',content_name:"",content_weight:'',content_comment:'',invoice:''}]};
        console.log(release)
        return(
            <ReleaseFormIn release={release} />
        );
    } else if(type === 'reload'){
        const release = {id:0, date_from:'', date_to:'', release:'',type_release:'IN',description:'',user:store.user.name, status:1, full:1,unload:1,unload_type:1,photo:1,type_container:'',number_out:1 , delivery_address:"", agreed_price:'', containers:[{number:'', type:'',car_number:'', content:'', fio:'',count:1,connect:0,content_count:'',content_name:"",content_weight:'',content_comment:'',invoice:''}]};
        return(
            <ReleaseFormIn release={release} />
        );
    } else if(type === 'out'){
        const release = {id:0, date_from:'', date_to:'', release:'',type_release:'OUT',description:'',  user:store.user.name, add_email:'', status:1, car_firm:'', delivery_address:"", agreed_price:'', containers:[{number:'', type:'',car_number:'', fio:'',count:1}]};
        return (
            <ReleaseFormOut release={release} currency={store.currency} />
        );
    } else if (type === 'cars') {
        const release = {id:0, date_from:'', date_to:'', release:'',type_release:'OUT',description:'',  user:store.user.name, add_email:'', status:1, car_firm:'', delivery_address:"", agreed_price:'', containers:[{number:'', type:'',count:1}],cars:[{fio:'',number:'',count:1}]};
        return (
            <ReleaseFormOutF release={release} currency={store.currency} />
        );
    }
};

export default CreateRelease;
